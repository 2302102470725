import React, { ReactNode } from 'react'

interface ContainerProps {
  children: ReactNode
}

const Container = ({ children }: ContainerProps) => {
  return (
    <div
      style={{
        margin: '0 auto',
        minHeight: '100vh',
        overflowX: 'hidden',
      }}
    >
      {children}
    </div>
  )
}

export default Container
