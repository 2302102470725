import React, { ReactNode } from 'react'

interface ContentContainerProps {
  children: ReactNode
}

const ContentContainer = (props: ContentContainerProps) => {
  return (
    <div className="w-screen bg-gray-300">
      <div className="w-full m-auto pt-12 md:pt-16 min-h-screen">
        {props.children}
      </div>
    </div>
  )
}

export default ContentContainer
