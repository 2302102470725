import React from 'react'

interface ButtonProps {
  children: React.ReactNode
  type: string
}

const Button = (props: ButtonProps) => {
  return (
    <div
      className={`flex justify-center items-center border-2 py-2 rounded lg text-brand shadow-md cursor-pointer ${
        props.type === 'primary'
          ? 'border-brand hover:shadow-lg hover:text-black bg-brand text-black'
          : 'bg-brandRed border-brandRed text-brand hover:shadow-2xl font-handwritten'
      }`}
    >
      {props.children}
    </div>
  )
}

export default Button
