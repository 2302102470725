import React from 'react'
import Container from './container'
import Navigation from '../navigation'
import '../../../styles/tailwind.css'
import Footer from '../Footer'
import CookieConsent from 'react-cookie-consent'
import ContentContainer from './content-container'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Container>
        <Navigation />
        <ContentContainer>{children}</ContentContainer>
        <Footer />
      </Container>
    )
  }
}

export default Template
