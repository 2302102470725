import React from 'react'

const TextContainer = (props: any) => {
  return (
    <div className="sm:w-5/6 md:w-3/4 lg:w-2/3 xl:w-1/2 m-auto px-2 md:px-0">
      {props.children}
    </div>
  )
}

export default TextContainer
