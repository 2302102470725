import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Button from './button'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Navigation = (props: any) => {
  const [hidden, setHidden] = useState(true)

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "icons/logo_square.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      fb: file(relativePath: { eq: "icons/fb.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      ig: file(relativePath: { eq: "icons/ig.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const links = [
    { to: '/team', text: 'Wer wir sind' },
    { to: '/projekt', text: 'Was wir machen' },
    { to: '/spenden', text: 'Wie du helfen kannst' },
  ]

  const toggle = () => {
    setHidden(!hidden)
  }

  return (
    <nav
      className="bg-white shadow-lg fixed w-screen z-50 border-brand border-b-4 flex justify-end items-end md:pr-8"
      role="navigation"
    >
      <div className="container p-2 w-full md:w-2/3 flex flex-wrap items-end md:flex-no-wrap">
        <div
          className={`w-12 md:w-32 shadow-lg p-1 absolute top-0 left-0 mt-2 md:mt-4 ml-4 bg-white z-50 rounded-lg ${
            hidden ? 'block' : 'hidden'
          }`}
          style={{ boxSizing: 'content-box' }}
        >
          <Link to="/">
            <Img fluid={data.logo.childImageSharp.fluid} alt="" />
          </Link>
        </div>
        <div className="ml-auto md:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded"
            type="button"
            onClick={toggle}
          >
            <svg
              className="h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          className={`w-full justify-between md:w-auto md:flex-grow md:flex md:items-center ${
            hidden ? 'hidden' : 'block'
          }`}
        >
          <div
            className="flex flex-col md:flex-row items-end justify-end m-0 mr-4"
            onClick={toggle}
          >
            {links.map(link => {
              return (
                <Link
                  className="no-underline text-lg py-3 md:mr-8 hover:text-brand font-handwritten"
                  activeClassName="text-brand"
                  to={link.to}
                  key={link.to}
                >
                  {link.text}
                </Link>
              )
            })}
          </div>

          <div className="flex items-center justify-end md:justify-center">
            <div className="h-full flex justify-around w-24 items-center mr-4">
              <OutboundLink
                href="https://www.instagram.com/shineongermany/"
                target="_blank"
                className="w-8 h-full "
              >
                <Img fluid={data.ig.childImageSharp.fluid} alt="" />
              </OutboundLink>
              <OutboundLink
                href="https://www.facebook.com/shineongermany"
                target="_blank"
                className="w-8 h-full"
              >
                <Img
                  fluid={data.fb.childImageSharp.fluid}
                  alt=""
                  style={{ width: '100%', height: '100%' }}
                />
              </OutboundLink>
            </div>

            <Button type="primary">
              <Link
                to={'/spenden'}
                className="font-handwritten mx-4 w-full text-center"
              >
                Jetzt Spenden
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
