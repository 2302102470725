import React from 'react'
import { Link } from 'gatsby'
import Icon from '@iconify/react'
import facebookFill from '@iconify/icons-ant-design/facebook-fill'
import instagramOutline from '@iconify/icons-ant-design/instagram-outline'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Footer = () => (
  <div
    className="w-screen bg-brandRed flex flex-col md:flex-row justify-between items-start text-white px-12 pt-12 pb-12 text-brand"
    style={{ fontFamily: 'Cormorant' }}
  >
    <div className="flex flex-col md:flex-row ">
      <div className="flex flex-col h-full w-64 mt-8 md:mt-0">
        <span className="font-bold m-0">Über uns</span>
        <Link to="/team" className="text-brand hover:text-white">
          Wer wir sind
        </Link>
        <Link to="/projekt" className="text-brand hover:text-white">
          Was wir machen
        </Link>
        <Link to="/spenden" className="text-brand hover:text-white">
          Wie du helfen kannst
        </Link>
      </div>

      <div className="flex flex-col h-full w-64 mt-8 md:mt-0">
        <span className="font-bold m-0">Social Media</span>
        <OutboundLink
          href="https://www.instagram.com/shineongermany/"
          target="_blank"
          className="flex items-center text-brand"
        >
          <Icon
            icon={instagramOutline}
            width="2rem"
            className="hover:text-brand cursor-pointer"
          />
          Instagram
        </OutboundLink>
        <OutboundLink
          href="https://www.facebook.com/shineongermany"
          target="_blank"
          className="flex items-center text-brand"
        >
          <Icon
            icon={facebookFill}
            width="2rem"
            className="hover:text-brand cursor-pointer"
          />
          Facebook
        </OutboundLink>
      </div>

      <div className="flex flex-col h-full w-64 br-2 border-brand mt-8 md:mt-0">
        <span className="font-bold m-0">Kontakt</span>
        <Link to="/kontakt" className="text-brand hover:text-white">
          Kontaktformular
        </Link>
      </div>
    </div>

    <div className="flex flex-col pr-4 mt-8 md:mt-0">
      <span className="font-bold m-0">Rechtliches</span>
      <Link to="/imprint" className="text-brand hover:text-white">
        Impressum
      </Link>
      <Link to="/privacy" className="text-brand hover:text-white">
        Datenschutz
      </Link>
    </div>
  </div>
)

Footer.propTypes = {}

export default Footer
